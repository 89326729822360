import React, { Suspense, useEffect, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { usePostStore } from 'three/Renderer/store';
import Renderer from '../Renderer/Renderer';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackUI from 'components/ErrorFallbackUI';
import { useDebugThreeStore } from 'services/DebugThreeService';
import ProgressMonitor from 'components/Play/DistrictCanvas/ProgressMonitor';
import { TransformControls } from '@react-three/drei';
import { useQualitySettings } from '../../services/QualitySettings';

function BGColor({ color }) {
  const { gl } = useThree();
  useEffect(() => {
    gl.setClearColor(color);
  }, []);
}

function SetDebugScene() {
  const { scene, gl } = useThree();

  useEffect(() => {
    scene.name = 'Scene';

    if (!gl.renderOrg) {
      gl.renderOrg = gl.render;
    }
    gl.render = (scene, camera) => {
      const t = performance.now();
      const r = gl.renderOrg(scene, camera);
      if (scene.name === 'Scene') {
        gl.info.renderMs = performance.now() - t;
      }
      return r;
    };

    useDebugThreeStore.getState().setDebugScene(scene);
    return () => {
      useDebugThreeStore.getState().setDebugScene(null);
    };
  }, []);
}

function FrameLimiter() {
  const maxFps = usePostStore(state => state.renderConfiguration.maxFps);

  const { invalidate } = useThree();
  useEffect(() => {
    let running = true;
    let lastRenderMs = performance.now();

    const f = () => {
      if (running) requestAnimationFrame(f);

      const now = performance.now();
      const dt = now - lastRenderMs;

      if (dt < (1000 / maxFps) * 0.85) return;
      lastRenderMs = now;
      invalidate();
    };

    f();
    return () => {
      running = false;
    };
  }, [maxFps]);

  return null;
}

function DefaultError({ error }) {
  return <ErrorFallbackUI message={error.message} />;
}

function DefaultDone() {
  return null;
}

export default function ThreeCanvas({
  DoneComponent = DefaultDone,
  ErrorComponent = DefaultError,
  children,
  forceRendering = false,
  clearColor = 0x0,
}) {
  const defaultPixelRatio = usePostStore(state => state.renderConfiguration.pixelRatio);
  const maxPixelArea = usePostStore(state => state.renderConfiguration.maxPixelArea);
  const [pixelRatio, setPixelRatio] = useState(defaultPixelRatio);

  useEffect(() => {
    const resize = () => {
      const maxTargetPixelArea = maxPixelArea * maxPixelArea;
      const aspect = window.innerWidth / window.innerHeight;
      const maxH = Math.sqrt((1 / aspect) * maxTargetPixelArea);
      const maxDpr = maxH / window.innerHeight;

      const bestPixelRatio = Math.min(window.devicePixelRatio, defaultPixelRatio);
      setPixelRatio(Math.min(maxDpr, bestPixelRatio));
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [maxPixelArea, defaultPixelRatio]);

  const transformControls = useDebugThreeStore(state => state.transformControls);
  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <Canvas
        dpr={pixelRatio}
        onContextMenu={e => {
          e.nativeEvent.preventDefault();
        }}
        flat={true}
        shadows={true}
        frameloop={'demand'}
      >
        <FrameLimiter />
        <SetDebugScene />
        {transformControls?.object && transformControls.object.parent && (
          <TransformControls
            userData={{ hideInDebug: true }}
            translationSnap={0.001}
            scaleSnap={0.001}
            rotationSnap={0.001}
            object={transformControls.object}
            mode={transformControls.mode}
          />
        )}
        <ProgressMonitor />
        <Suspense fallback={null}>
          <Renderer forceRendering={forceRendering} />
          <BGColor color={clearColor} />
          <Suspense fallback={null}>
            {children}
            <DoneComponent />
          </Suspense>
        </Suspense>
      </Canvas>
    </ErrorBoundary>
  );
}
