import React, { useEffect } from 'react';
import { useUserStore } from 'services/UserService';
import Profile from './Profile';
import { usePlayerStore } from 'services/PlayerService';
import { MODE_FITTING } from 'services/PlayerService/constants';

function ProfileMenu({ updateSize }) {
  useEffect(() => {
    return () => {
      useUserStore.getState().selectUserById(null);
    };
  }, []);
  useEffect(updateSize, [updateSize]);
  const isFitting = usePlayerStore(state => state.mode === MODE_FITTING);
  const playerIsExpert = useUserStore(state => state.user.role.type === 'expert');
  const selectedUser = useUserStore(state => state.users.find(u => u.id === state.selectedUserId));
  const user = selectedUser ? selectedUser : useUserStore.getState().user;

  // hide profile in fitting view to avoid UI overlap
  if (isFitting) return null;

  const tabs = null;

  return user ? <Profile tabs={tabs} updateSize={updateSize} user={user} playerIsExpert={playerIsExpert} /> : null;
}

export default ProfileMenu;
