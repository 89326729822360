import { useQualitySettings } from 'services/QualitySettings';
import { Item, ItemSmall } from '../styles';

export default function RenderQualityPanel({ showDevOptions = false }) {
  const options = useQualitySettings(state => state.options);
  return (
    <div style={{ display: 'grid', gridAutoFlow: 'column', gridGap: 5 }}>
      {options.map((o, i) => {
        if (!showDevOptions && i > 2) return null;
        return (
          <ItemSmall
            key={i}
            selected={o.selected}
            onClick={() => {
              useQualitySettings.getState().changeQualityOption(i);
            }}
          >
            {o.label}
            {o.default ? ' (default)' : ''}
          </ItemSmall>
        );
      })}
    </div>
  );
}
