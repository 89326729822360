import create from 'utilities/zustand/create';
import { getGPUTier } from 'detect-gpu';

export const useGpuDetect = create(set => ({
  tier: 2,
  isMobile: true,
  fps: 0,

  async init() {
    const gpu = await getGPUTier();
    set({ tier: gpu.tier, isMobile: gpu.isMobile, fps: gpu.fps });
  },
}));
