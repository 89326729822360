import create from 'utilities/zustand/create';
import { Howl, Howler } from 'howler';
import { getEventSoundConfig } from './config';

export const useHowlerStore = create((set, get) => ({
  isHowlerEnabled: false,
  effectSoundsConfig: [],
  effectSounds: [],

  init: () => {
    const eventSoundConfig = getEventSoundConfig();
    const effectSounds = eventSoundConfig.effectSounds;
    set({ effectSoundsConfig: effectSounds });
    get().initEffectSounds(effectSounds);
  },

  setEnabled: enabled => {
    set({ isHowlerEnabled: enabled });
  },

  initEffectSounds: effectSoundsConfig => {
    if (effectSoundsConfig) {
      const effectSoundsHowls = effectSoundsConfig.map(sound => {
        const howl = new Howl({
          src: [sound.src.webm, sound.src.mp3],
          loop: sound.loop,
        });
        howl.name = sound.name;
        return {
          name: sound.name,
          howl: howl,
        };
      });

      set({
        effectSounds: [...effectSoundsHowls],
      });
    }
  },

  getEffectSound: soundName => {
    const effectSound = get().effectSounds.find(sound => sound.name === soundName);
    return effectSound?.howl;
  },

  createEffectSound: soundName => {
    const effectSounds = get().effectSoundsConfig.find(sound => sound.name === soundName);
    if (effectSounds) {
      const howl = new Howl({
        src: [effectSounds.src.webm, effectSounds.src.mp3],
        loop: effectSounds.loop,
      });
      howl.name = 'c-' + soundName;
      return howl;
    }
  },

  playEffectSounds: reactionId => {
    const emoji = get().getEffectSound('emoji');
    const isEmojiAllowedToPlay = reactionId !== 0 && reactionId !== 10 && get().isHowlerEnabled;
    isEmojiAllowedToPlay ? emoji?.play() : emoji?.stop();
  },

  playClickUiSound: () => {
    const clickSound = get().getEffectSound('clickUi');

    get().isHowlerEnabled && clickSound.play() && !clickSound.playing();
  },

  fadeOutSound: () => {
    Howler.mute(true);
  },

  fadeInSound: () => {
    Howler.mute(false);
  },
}));
