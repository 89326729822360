import IconShoes from './icons/category/icon_shoes.png';
import IconSkin from './icons/category/icon_skin.png';
import IconTop from './icons/category/icon_top.png';
import IconHair from './icons/category/icon_hair.png';
import IconGlasses from './icons/category/icon_glasses.png';
import IconFacialHair from './icons/category/icon_facial_details.png';
import Thumb_Glasses_00 from './icons/options/glasses/Thumb_Glasses_00.png';

import female_open_long from './icons/options_gen3/hair/female_open_long.jpg';
import female_open_short from './icons/options_gen3/hair/both_open_short.jpg';
import female_ponytail from './icons/options_gen3/hair/female_ponytail.jpg';
import female_big_shape from './icons/options_gen3/hair/female_big_shape.jpg';
import female_chinese from './icons/options_gen3/hair/female_chinese.jpg';
import female_side_short from './icons/options_gen3/hair/female_side_short.jpg';

import male_afro from './icons/options_gen3/hair/both_afro.jpg';
import male_combed_backwards from './icons/options_gen3/hair/male_combed_backwards.jpg';
import male_short_thick from './icons/options_gen3/hair/male_short_thick.jpg';
import male_short_thin from './icons/options_gen3/hair/male_short_thin.jpg';

import outfit_youngstylish_black from './icons/options_gen3/outfits/youngstylish_black.jpg';
import outfit_youngstylish_yellow from './icons/options_gen3/outfits/youngstylish_yellow.jpg';
import outfit_airjordan_light from './icons/options_gen3/outfits/airjordan_light.jpg';
import outfit_airjordan_dark from './icons/options_gen3/outfits/airjordan_dark.jpg';
import outfit_tracksuit_colors from './icons/options_gen3/outfits/tracksuit_colors.jpg';
import outfit_tracksuit_white from './icons/options_gen3/outfits/tracksuit_white.jpg';
import outfit_bubblejacket_black from './icons/options_gen3/outfits/bubblejacket_black.jpg';
import outfit_bubblejacket_white from './icons/options_gen3/outfits/bubblejacket_white.jpg';
import outfit_suit_blue from './icons/options_gen3/outfits/suit_blue.jpg';
import outfit_suit_white from './icons/options_gen3/outfits/suit_white.jpg';

import outfit_trousersshirt_blackwhite from './icons/options_gen3/outfits/trousershirt_blackwhite.jpg';
import outfit_trousersshirt_colors from './icons/options_gen3/outfits/trousershirt_colors.jpg';

import beard_circle from './icons/options_gen3/beards/beard_circle.jpg';
import beard_bubbles from './icons/options_gen3/beards/beard_bubbles.jpg';
import beard_full from './icons/options_gen3/beards/beard_full.jpg';
import beard_lumberjack from './icons/options_gen3/beards/beard_lumberjack.jpg';
import moustache_curly from './icons/options_gen3/beards/moustache_curly.jpg';
import moustache_dali from './icons/options_gen3/beards/moustache_dali.jpg';
import moustache_fumanchu from './icons/options_gen3/beards/moustache_fumanchu.jpg';
import moustache_normal from './icons/options_gen3/beards/moustache.jpg';

import glasses_aviator_sun from './icons/options_gen3/glasses/aviator_sun.jpg';
import glasses_wayfarer_black_sun from './icons/options_gen3/glasses/wayfarer_black_sun.jpg';
import glasses_wayfarer_horn_sun from './icons/options_gen3/glasses/wayfarer_horn_sun.jpg';
import glasses_round_metal_sun from './icons/options_gen3/glasses/round_metal_sun.jpg';
import glasses_round_darkhorn_sun from './icons/options_gen3/glasses/round_darkhorn_sun.jpg';

import glasses_aviator_optic from './icons/options_gen3/glasses/aviator_optic.jpg';
import glasses_wayfarer_black_optic from './icons/options_gen3/glasses/wayfarer_black_optic.jpg';
import glasses_wayfarer_horn_optic from './icons/options_gen3/glasses/wayfarer_horn_optic.jpg';

import glasses_round_metal_optic from './icons/options_gen3/glasses/round_metal_optic.jpg';
import glasses_round_darkhorn_optic from './icons/options_gen3/glasses/round_darkhorn_optic.jpg';
import glasses_thoughlife from './icons/options_gen3/glasses/thoughlife.jpg';

import glasses_vr_black from './icons/options_gen3/glasses/vr_black.jpg';
import glasses_vr_white from './icons/options_gen3/glasses/vr_white.jpg';
import glasses_bttf from './icons/options_gen3/glasses/bttf.jpg';

import shoes_balenciaga_black_green from './icons/options_gen3/shoes/balenciaga_black_green.jpg';
import shoes_balenciaga_pink from './icons/options_gen3/shoes/balenciaga_pink.jpg';
import shoes_balenciaga_black_white_red from './icons/options_gen3/shoes/balenciaga_black_white_red.jpg';

import shoes_sneaker_white from './icons/options_gen3/shoes/sneaker_white.jpg';
import shoes_sneaker_colorful from './icons/options_gen3/shoes/sneaker_colorful.jpg';
import shoes_sneaker_black from './icons/options_gen3/shoes/sneaker_black.jpg';
import shoes_sneaker_pink from './icons/options_gen3/shoes/sneaker_pink.jpg';

import shoes_chelseaboots_black from './icons/options_gen3/shoes/chelseaboots_black.jpg';
import shoes_chelseaboots_brown from './icons/options_gen3/shoes/chelseaboots_brown.jpg';

import { character, characterGen3 } from './colors';
import { useTranslationStore } from 'services/TranslationService';

const avatarCameraHead = {
  target: { y: 1.2 },
  angleLimit: { x: 0.2, y: 1.0 },
  distance: 2.0,
};

const avatarCameraFull = {
  target: { y: 0.8 },
  angleLimit: { x: 0.1, y: 1.0 },
  distance: 2.7,
};

export const getInitialCustomizationConfigurationGen3 = () => {
  const translate = useTranslationStore.getState().translate;
  return [
    {
      id: 'hair',
      name: translate('avatar.tab.hair') || 'Hairstyle',
      selected: false,
      customized: false,
      icon: IconHair,
      avatarCamera: avatarCameraHead,
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Glasses_00, name: 'hair_NONE' },
            { icon: female_open_long, name: 'hair_female_open_long' },
            { icon: male_short_thin, name: 'hair_male_short_thin' },
            { icon: female_ponytail, name: 'hair_female_ponytail' },
            { icon: male_short_thick, name: 'hair_male_short_thick' },
            { icon: male_combed_backwards, name: 'hair_male_combed_backwards' },
            // { icon: female_big_shape, name: 'hair_female_big_shape' },
            { icon: male_afro, name: 'hair_male_afro' },
            { icon: female_open_short, name: 'hair_female_open_short' },
            { icon: female_side_short, name: 'hair_female_side_short' },
            { icon: female_chinese, name: 'hair_female_chinese' },
          ],
          selections: [{ name: 'default', index: 1 }],
        },
        {
          type: 'color',
          options: [
            character.hair['1'],
            character.hair['2'],
            character.hair['3'],
            character.hair['4'],
            character.hair['5'],
            character.hair['6'],
            character.hair['7'],
            character.hair['8'],
            character.hair['9'],
            character.hair['10'],
            character.hair['11'],
            character.hair['12'],
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'facial_hair',
      name: translate('avatar.tab.facial_hair') || 'Facial hair',
      selected: false,
      customized: false,
      icon: IconFacialHair,
      avatarCamera: avatarCameraHead,
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Glasses_00, name: 'accesoires_NONE' },
            { icon: moustache_dali, name: 'beards_mustache07_mdl' },
            { icon: moustache_fumanchu, name: 'beards_mustache14_mdl' },
            { icon: moustache_curly, name: 'beards_mustache01_mdl' },
            { icon: moustache_normal, name: 'beards_mustache05_mdl' },
            { icon: beard_circle, name: 'beards_beard04_mdl' },
            { icon: beard_full, name: 'beards_beard03_mdl' },
            { icon: beard_bubbles, name: 'beards_beard02_mdl' },
            { icon: beard_lumberjack, name: 'beards_beard01_mdl' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
        {
          type: 'color',
          options: [
            character.hair['1'],
            character.hair['2'],
            character.hair['3'],
            character.hair['4'],
            character.hair['5'],
            character.hair['6'],
            character.hair['7'],
            character.hair['8'],
            character.hair['9'],
            character.hair['10'],
            character.hair['11'],
            character.hair['12'],
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'glasses',
      name: translate('avatar.tab.glasses') || 'Glasses',
      selected: false,
      customized: false,
      icon: IconGlasses,
      avatarCamera: avatarCameraHead,
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Glasses_00, name: 'accesoires_NONE' },
            { icon: glasses_aviator_sun, name: 'accesoires_aviatorglasses_mdl' },
            {
              icon: glasses_wayfarer_black_sun,
              name: 'accesoires_wayfarerglasses_mdl',
              materialSwitches: [{ from: 'frame_mat', to: 'accesoires_wayfarerglasses_4_mdl' }],
            },
            {
              icon: glasses_wayfarer_horn_sun,
              name: 'accesoires_wayfarerglasses_mdl',
              materialSwitches: [{ from: 'frame_mat', to: 'accesoires_wayfarerglasses_2_mdl' }],
            },
            {
              icon: glasses_round_metal_sun,
              name: 'accesoires_roundglasses_mdl',
              materialSwitches: [{ from: 'frame_mat', to: 'accesoires_wayfarerglasses_3_mdl' }],
            },
            {
              icon: glasses_round_darkhorn_sun,
              name: 'accesoires_roundglasses_mdl',
              materialSwitches: [{ from: 'frame_mat', to: 'accesoires_roundglasses_2_mdl' }],
            },
            {
              icon: glasses_aviator_optic,
              name: 'accesoires_aviatorglasses_mdl',
              materialSwitches: [{ from: 'glass_mat', to: 'glass_6_mdl' }],
            },
            {
              icon: glasses_wayfarer_black_optic,
              name: 'accesoires_wayfarerglasses_mdl',
              materialSwitches: [
                { from: 'frame_mat', to: 'accesoires_wayfarerglasses_4_mdl' },
                { from: 'glass_mat', to: 'glass_6_mdl' },
              ],
            },
            {
              icon: glasses_wayfarer_horn_optic,
              name: 'accesoires_wayfarerglasses_mdl',
              materialSwitches: [
                { from: 'frame_mat', to: 'accesoires_wayfarerglasses_2_mdl' },
                { from: 'glass_mat', to: 'glass_6_mdl' },
              ],
            },
            {
              icon: glasses_round_metal_optic,
              name: 'accesoires_roundglasses_mdl',
              materialSwitches: [
                { from: 'glass_mat', to: 'glass_6_mdl' },
                { from: 'frame_mat', to: 'accesoires_wayfarerglasses_3_mdl' },
              ],
            },
            {
              icon: glasses_round_darkhorn_optic,
              name: 'accesoires_roundglasses_mdl',
              materialSwitches: [
                { from: 'frame_mat', to: 'accesoires_roundglasses_2_mdl' },
                { from: 'glass_mat', to: 'glass_6_mdl' },
              ],
            },
            {
              icon: glasses_thoughlife,
              name: 'accesoires_mcglasses_mdl',
            },
            {
              icon: glasses_vr_white,
              name: 'accesoires_vrglasses_white_mdl',
            },
            {
              icon: glasses_vr_black,
              name: 'accesoires_vrglasses_mdl',
            },
            {
              icon: glasses_bttf,
              name: 'accesoires_bttfglasses_mdl',
            },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'skin',
      name: translate('avatar.tab.skin') || 'Skin color',
      selected: false,
      customized: false,
      icon: IconSkin,
      avatarCamera: avatarCameraFull,
      optionPanels: [
        {
          type: 'material',
          options: Object.values(characterGen3.skin),
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'outfit',
      name: translate('avatar.tab.outfit') || 'Outfit',
      selected: false,
      customized: false,
      icon: IconTop,
      avatarCamera: avatarCameraFull,
      optionPanels: [
        {
          type: 'style',
          options: [
            {
              icon: outfit_youngstylish_black,
              name: 'outfit_youngstylish_mdl',
              materialSwitches: [{ to: 'outfit_youngstylish_4_mdl' }],
            },
            { icon: outfit_youngstylish_yellow, name: 'outfit_youngstylish_mdl' },
            { icon: outfit_airjordan_light, name: 'outfit_airjordan_mdl' },
            {
              icon: outfit_airjordan_dark,
              name: 'outfit_airjordan_mdl',
              materialSwitches: [{ to: 'outfit_airjordan_2_mdl' }],
            },
            { icon: outfit_tracksuit_colors, name: 'outfit_techwear_mdl' },
            {
              icon: outfit_tracksuit_white,
              name: 'outfit_techwear_mdl',
              materialSwitches: [{ to: 'outfit_techwear_2_mdl' }],
            },
            {
              icon: outfit_bubblejacket_black,
              name: 'outfit_thickjacket_mdl',
              materialSwitches: [{ to: 'outfit_thickjacket_2_mdl' }],
            },
            { icon: outfit_bubblejacket_white, name: 'outfit_thickjacket_mdl' },
            {
              icon: outfit_trousersshirt_blackwhite,
              name: 'outfit_trousersshirt_mdl',
              materialSwitches: [{ to: 'outfit_trousersshirt_2_mdl' }],
            },
            { icon: outfit_trousersshirt_colors, name: 'outfit_trousersshirt_mdl' },
            { icon: outfit_suit_blue, name: 'outfit_fullsuit_mdl' },
            {
              icon: outfit_suit_white,
              name: 'outfit_fullsuit_mdl',
              materialSwitches: [{ to: 'outfit_fullsuit_6_mdl' }],
            },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'shoes',
      name: translate('avatar.tab.shoes') || 'Shoes',
      selected: false,
      customized: false,
      icon: IconShoes,
      avatarCamera: {
        target: { y: 0.3 },
        angleLimit: { x: 0.3, y: 0.8 },
        distance: 2.0,
      },
      optionPanels: [
        {
          type: 'style',
          options: [
            {
              icon: shoes_balenciaga_black_green,
              name: 'shoes_balenciaga_socks_mdl',
              materialSwitches: [{ to: 'shoes_balenciaga_socks_3_mdl' }],
            },
            { icon: shoes_balenciaga_pink, name: 'shoes_balenciaga_socks_mdl' },
            {
              icon: shoes_balenciaga_black_white_red,
              name: 'shoes_balenciaga_socks_mdl',
              materialSwitches: [{ to: 'shoes_balenciaga_socks_2_mdl' }],
            },

            { icon: shoes_sneaker_white, name: 'shoes_sneaker01_mdl' },
            {
              icon: shoes_sneaker_colorful,
              name: 'shoes_sneaker01_mdl',
              materialSwitches: [{ to: 'shoes_sneaker01_2_mdl' }],
            },
            {
              icon: shoes_sneaker_black,
              name: 'shoes_sneaker01_mdl',
              materialSwitches: [{ to: 'shoes_sneaker01_3_mdl' }],
            },

            {
              icon: shoes_sneaker_pink,
              name: 'shoes_sneaker01_mdl',
              materialSwitches: [{ to: 'shoes_sneaker01_4_mdl' }],
            },
            {
              icon: shoes_chelseaboots_brown,
              name: 'shoes_chelsea_boots_mdl',
              materialSwitches: [{ to: 'shoes_chelsea_boots_2_mdl' }],
            },
            { icon: shoes_chelseaboots_black, name: 'shoes_chelsea_boots_mdl' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },
  ];
};
