import create from 'utilities/zustand/create';
import { Euler, MathUtils, Matrix4, Quaternion, Vector2, Vector3 } from 'three';
import EASINGS from 'utilities/easings';
import { runAverage } from 'utilities/math';
import { Raycaster } from 'utilities/octree/Raycaster';

const EPSILON = 0.0001;

const raycaster = new Raycaster();
const zero = new Vector3(0, 0, 0);
const up = new Vector3(0, 1, 0);
const forward = new Vector3(0, 0, 1);

export const useCameraStore = create((set, get) => ({
  mode: 'init',
  screenFade: {
    color: 'white',
    fade: false,
    callback: () => {},
  },
  resetScreenFade: () => {
    set({
      screenFade: {
        color: 'white',
        fadeIn: false,
        callback: () => {},
      },
    });
  },
  startScreenFade: (callback, color = 'black') => {
    set({
      screenFade: {
        color: color,
        fadeIn: true,
        callback: callback,
      },
    });
  },
  position: new Vector3(),
  quaternion: new Quaternion(0, 0, 0, 1),
  euler: new Euler(),
  cursor: {
    cursorVelocity: new Vector2(),
    maxCursorSpeed: 0.1,
    velocityDamping: 8,
    smoothCursorPosition: new Vector2(),
    previousCursorPosition: new Vector2(),
    cursorDown: false,
    onCursorDown: () => {},
    velocitySamples: {
      x: [],
      y: [],
    },
    positionSamples: {
      x: [],
      y: [],
    },
    update: (delta, cursorPosition, cursorDown) => {
      const { cursor } = get();
      const {
        cursorVelocity,
        maxCursorSpeed,
        velocityDamping,
        smoothCursorPosition,
        previousCursorPosition,
        velocitySamples,
        onCursorDown,
      } = cursor;

      const cursorDelta = new Vector2();
      const rawCursorPosition = new Vector2(cursorPosition.x, cursorPosition.y);
      const cursorChanged = cursor.cursorDown != cursorDown;
      cursor.cursorDown = cursorDown;
      // update cursor velocity
      if (cursorDown) {
        if (cursorChanged) {
          smoothCursorPosition.copy(rawCursorPosition);
          previousCursorPosition.copy(rawCursorPosition);
          cursorVelocity.set(0, 0);
          velocitySamples.x = [];
          velocitySamples.y = [];
          onCursorDown();
          return;
        }
        smoothCursorPosition.lerp(rawCursorPosition, MathUtils.clamp(delta * 16, 0, 1));
        cursorDelta.subVectors(smoothCursorPosition, previousCursorPosition);
        previousCursorPosition.copy(smoothCursorPosition);

        cursorVelocity.set(
          runAverage(3, velocitySamples.x, cursorDelta.x),
          runAverage(3, velocitySamples.y, cursorDelta.y)
        );
        cursorVelocity.copy(cursorDelta);
      } else {
        if (cursorVelocity.length() < EPSILON) {
          cursorVelocity.set(0, 0);
        } else {
          cursorVelocity.lerp(zero, MathUtils.clamp(delta * velocityDamping, 0, 1));
        }
      }
      cursorVelocity.clampLength(0, maxCursorSpeed);
    },
  },
  oldOrbit: {
    enabled: false,
    offset: new Vector3(0, 1.83, 0),
    angleLimit: new Vector2(0, 0),
    smoothOffset: new Vector3(),
    distance: 3.33,
    distanceLerp: 3.33,
    euler: new Euler(0.3, 0, 0, 'YXZ'),
    orbitQuaternion: new Quaternion(0, 0, 0, 1),
    initialEuler: new Euler(0.3, 0, 0, 'YXZ'),
    following: false,
    smoothCursorDown: 0,
    resetFromPlayerRot: false,
    flip: false,
    init: () => {
      const { oldOrbit: orbit, cursor } = get();
      const { euler, initialEuler } = orbit;
      euler.copy(initialEuler);
      orbit.following = true;
      cursor.onCursorDown = orbit.onCursorDown;
    },
    onCursorDown: () => {
      const { oldOrbit: orbit } = get();
      orbit.resetFromPlayerRot = true;
    },
    setFlipped: flip => {
      const { oldOrbit: orbit } = get();
      orbit.flip = flip;
      if (flip) {
        orbit.euler.y = orbit.initialEuler.y;
      } else {
        orbit.following = true;
      }
    },
    startFollowing: () => {
      const { oldOrbit: orbit } = get();
      const { euler } = orbit;
      orbit.following = true;
      euler.y = 0.0;
    },
    stopFollowing: () => {
      const { oldOrbit: orbit } = get();
      orbit.following = false;
    },
    update: orbitParams => {
      const { oldOrbit: orbit, setOrbiting, cursor } = get();
      const {
        initialEuler,
        orbitQuaternion,
        euler,
        following,
        stopFollowing,
        offset,
        angleLimit,
        smoothOffset,
        distance,
        //cursorVelocity,
        flip,
      } = orbit;
      const { delta, position, rotation, collisionMesh } = orbitParams;
      // const rawCursorPosition = new Vector2(cursorPosition.x, cursorPosition.y);
      // smoothCursorPosition.lerp(rawCursorPosition, delta * 16);
      // const cursorDelta = new Vector2();
      // cursorDelta.subVectors(smoothCursorPosition, previousCursorPosition);
      // previousCursorPosition.copy(smoothCursorPosition);

      // create player vec3 and quaternion
      const playerPos = new Vector3(position[0], position[1], position[2]);
      const playerEul = new Euler(0, flip ? rotation + Math.PI : rotation, 0);
      const playerQuat = new Quaternion().setFromEuler(playerEul);

      // stop lerping and just set euler to current rotation (minus player rotation)
      if (orbit.resetFromPlayerRot) {
        orbit.resetFromPlayerRot = false;
        //cursorVelocity.set(0, 0);
        orbit.smoothCursorDown = 0;
        const orbitQuatMinusPlayer = new Quaternion();
        orbitQuatMinusPlayer.multiplyQuaternions(playerQuat.invert(), orbitQuaternion);
        euler.setFromQuaternion(orbitQuatMinusPlayer);
        return;
      }

      // apply cursor delta to orbit direction
      if (cursor.cursorDown) {
        //cursorVelocity.set(cursorDelta.x, cursorDelta.y);
        stopFollowing();
      } else {
        if (following) {
          if (Math.abs(euler.x - initialEuler.x) > EPSILON) {
            euler.x = initialEuler.x;
            euler.y = initialEuler.y;
          }
          //cursorVelocity.set(0, 0);
          orbit.smoothCursorDown = 1;
        } else {
          //cursorVelocity.lerp(new Vector2(0, 0), delta * velocityDamping);
          if (orbit.smoothCursorDown > 1 - EPSILON) {
            orbit.smoothCursorDown = 1;
          } else {
            orbit.smoothCursorDown = MathUtils.lerp(orbit.smoothCursorDown, 1, delta * 8);
          }
        }
      }
      //cursorVelocity.clampLength(0, maxCursorSpeed);
      const { cursorVelocity } = cursor;
      euler.x += cursorVelocity.y * 8.0;
      euler.y += -cursorVelocity.x * 8.0;
      euler.x = MathUtils.clamp(euler.x, angleLimit.x, angleLimit.y);
      const playerAndOrbitQuat = new Quaternion();
      const orbitQuat = new Quaternion();
      orbitQuat.setFromEuler(euler);

      // multiply player direction and orbit direction
      playerAndOrbitQuat.copy(playerQuat).multiply(orbitQuat);

      const l = 1.0 - orbit.smoothCursorDown * Math.pow(0.02, delta);
      orbitQuaternion.slerp(playerAndOrbitQuat, l);

      // set camera orbit position and rotation
      const camOrigin = new Vector3();
      smoothOffset.lerp(offset, delta * 4);

      camOrigin.addVectors(playerPos, smoothOffset);
      orbit.distanceLerp = MathUtils.lerp(orbit.distanceLerp, distance, delta * 4);
      setOrbiting(camOrigin, orbitQuaternion, orbit.distanceLerp, collisionMesh);
    },
  },
  orbit: {
    enabled: false,
    orbitOrigin: new Vector3(1, 1, -1),
    distance: 6,
    orbitEuler: new Euler(0, 0, 0, 'YXZ'),
    orbitQuaternion: new Quaternion(0, 0, 0, 1),
    initialEuler: new Euler(0.3, Math.PI, 0, 'YXZ'),
    init: () => {
      const { orbit } = get();
      const { orbitEuler, initialEuler } = orbit;
      orbitEuler.copy(initialEuler);
    },
    update: () => {
      const { orbit, cursor } = get();
      const { setOrbiting, orbitQuaternion, orbitEuler, orbitOrigin, distance } = orbit;
      orbitEuler.x += cursor.cursorVelocity.y * 8.0;
      orbitEuler.y += -cursor.cursorVelocity.x * 8.0;
      orbitEuler.x = MathUtils.clamp(orbitEuler.x, 0, 0.2 * Math.PI);

      const orbitQuat = new Quaternion();
      orbitQuat.setFromEuler(orbitEuler);
      orbitQuaternion.copy(orbitQuat);

      // set camera orbit position and rotation
      setOrbiting(orbitOrigin, orbitQuaternion, distance);
    },
    setOrbiting: (orbitOrigin, orbitDirQuaternion, orbitDistance) => {
      const camDirection = new Vector3(0, 0.0, -1.0);
      camDirection.applyQuaternion(orbitDirQuaternion);
      const offset = camDirection.clone();
      offset.multiplyScalar(orbitDistance);
      const { setPosition, lookAt } = get();
      const v = new Vector3();
      v.addVectors(orbitOrigin, offset);
      setPosition(v);
      lookAt(orbitOrigin);
    },
  },
  boom: {
    enabled: false,
    orbitOrigin: new Vector3(-0.108, 0, -0.3),
    lastOrigin: new Vector3(),
    lookTarget: new Vector3(),
    lookRot: 0,
    target: {
      focus: false,
      cursor: [0, 0],
      dist: 0,
      offset: [0, 0, 0],
      pos: [0, 0, 0],
    },
    targetLerp: 0,
    clampedOrbit: new Vector2(0, 0),
    orbitVector: new Vector2(),
    distances: new Vector3(),
    orbitEuler: new Euler(0, 0, 0, 'YXZ'),
    speed: 1,
    limits: {
      vertical: [1, 3],
      horizontal: [Math.PI, -Math.PI],
    },
    init: ({ distances, initial, limits }) => {
      const { boom } = get();
      boom.distances.copy(distances);
      boom.clampedOrbit.copy(initial);
      boom.lookRot = initial.x;
      boom.limits = limits;
    },
    update: delta => {
      const { boom, cursor, bezier, setBoom } = get();
      const { clampedOrbit, orbitOrigin, distances, lookTarget, targetLerp, orbitVector, limits, speed } = boom;
      const d = delta * speed;

      const [x, y, z] = boom.target.pos;
      const [x2, y2, z2] = boom.target.offset;
      const targetOrigin = new Vector3(x + x2, y + y2, z + z2);
      const targetOrbit = new Vector2(boom.target.cursor[0], boom.target.cursor[1]);
      if (boom.target.focus) {
        if (boom.targetLerp < 1) {
          boom.targetLerp = Math.min(boom.targetLerp + d, 1);
        } else if (!boom.target.isFocused) {
          boom.target.isFocused = true;
          set({ boom: { ...boom } });
          clampedOrbit.copy(orbitVector);
        }
        if (boom.targetLerp > 0.1 && !boom.target.isHalfWay) {
          boom.target.isHalfWay = true;
          set({ boom: { ...boom } });
        }
      } else {
        boom.targetLerp = Math.max(boom.targetLerp - d, 0);
        clampedOrbit.x += -cursor.cursorVelocity.x * 4.0;
        clampedOrbit.x = Math.min(clampedOrbit.x, limits.horizontal[0]);
        clampedOrbit.x = Math.max(clampedOrbit.x, limits.horizontal[1]);
        clampedOrbit.y += cursor.cursorVelocity.y * 8.0;
        clampedOrbit.y = MathUtils.clamp(clampedOrbit.y, limits.vertical[0], limits.vertical[1]);
      }

      const t = boom.target.focus ? EASINGS.easeOutQuad(targetLerp) : EASINGS.easeInQuad(targetLerp);

      lookTarget.lerpVectors(orbitOrigin, targetOrigin, t);
      orbitVector.lerpVectors(clampedOrbit, targetOrbit, t);
      const dist = boom.target.dist;
      distances.lerpVectors(new Vector3(9, 6, 8), new Vector3(dist, dist, dist), t);

      const linearHeight = MathUtils.mapLinear(orbitVector.y, limits.vertical[0], limits.vertical[1], 0, 1);
      const lookOffsetV = MathUtils.lerp(2, 0, linearHeight);
      const orbitDistance = bezier(distances.x, distances.y, distances.z, linearHeight);
      setBoom({
        orbitVector,
        orbitDistance,
        lookTarget,
        lookOffsetV,
      });
    },
  },
  transition: {
    enabled: false,
    lerp: 0,
    speed: 1,
    ease: t => t,
    start: (from, to, callbacks, duration, ease) => {
      const { transition } = get();
      transition.lerp = 0;
      transition.enabled = true;
      set({ transition: { ...transition, from, to, callbacks, speed: 1 / duration, ease } });
    },
    update: delta => {
      const { transition } = get();
      if (transition.lerp < 1) {
        transition.move();
        transition.lerp += delta * transition.speed;
        if (transition.callbacks) {
          const cbs = transition.callbacks;
          cbs.forEach(cb => {
            if (!cb.called && cb.t < transition.lerp) {
              cb.callback();
              cb.called = true;
            }
          });
        }
      } else {
        transition.lerp = 1;
        transition.move();
        transition.enabled = false;
        transition.callbacks = null;
      }
    },
    move: () => {
      const { transition, setBoom } = get();
      const { from, to, lerp, ease } = transition;
      const l = ease(lerp);
      const orbitVector = new Vector3().lerpVectors(from.orbitVector, to.orbitVector, l);
      const lookTarget = new Vector3().lerpVectors(from.lookTarget, to.lookTarget, l);
      const orbitDistance = MathUtils.lerp(from.orbitDistance, to.orbitDistance, l);
      const lookOffsetV = MathUtils.lerp(from.lookOffsetV, to.lookOffsetV, l);
      setBoom({
        orbitVector,
        orbitDistance,
        lookTarget,
        lookOffsetV,
      });
    },
  },
  setOrbiting: (orbitOrigin, orbitDirQuaternion, orbitDistance, collisionMesh) => {
    // rotate camera either behind the player or specified orbit
    const camDirection = new Vector3(0, 0.0, -1.0);
    camDirection.applyQuaternion(orbitDirQuaternion);
    const offset = camDirection.clone();
    offset.multiplyScalar(orbitDistance);
    if (collisionMesh) {
      const meshes = [];
      if (collisionMesh.walls) {
        meshes.push(collisionMesh.walls);
      }
      if (collisionMesh.ground) {
        meshes.push(collisionMesh.ground);
      }
      if (meshes.length > 0) {
        raycaster.set(orbitOrigin, camDirection);
        const intersects = raycaster.intersectObjects(meshes, true, null, false);
        if (intersects.length > 0) {
          const distance = intersects[0].distance;
          if (distance < orbitDistance) {
            offset.copy(camDirection);
            offset.multiplyScalar(Math.max(distance - 0.5, 0.15));
          }
        }
      }
    }

    const { setPosition, lookAtFrom, position } = get();
    const vec = new Vector3();
    vec.addVectors(orbitOrigin, offset);

    const a = -0.6;
    vec.y += a;
    setPosition(vec);

    const b = 0.2;
    orbitOrigin.y += b;
    lookAtFrom(orbitOrigin, position);
  },
  setBoom: values => {
    const { orbitVector, orbitDistance, lookTarget, lookOffsetV } = values;
    const v = new Vector3();
    const mat = new Matrix4();
    // set the distance
    mat.makeTranslation(0, 0, orbitDistance);
    v.applyMatrix4(mat);
    // rotate around
    mat.makeRotationFromEuler(new Euler(0, orbitVector.x, 0));
    v.applyMatrix4(mat);
    // move vertically
    mat.makeTranslation(0, orbitVector.y, 0);
    v.applyMatrix4(mat);
    // move to target
    mat.makeTranslation(lookTarget.x, lookTarget.y, lookTarget.z);
    v.applyMatrix4(mat);

    const { setPosition, lookAt } = get();
    setPosition(v);
    // look at target
    const v2 = new Vector3();
    v2.copy(lookTarget);
    v2.y = lookOffsetV;
    lookAt(v2);
  },
  bezier: (a, b, c, t) => {
    return MathUtils.lerp(MathUtils.lerp(a, b, t), MathUtils.lerp(b, c, t), t);
  },
  setMatrix: mat => {
    const { position, euler, quaternion } = get();
    position.setFromMatrixPosition(mat);
    quaternion.setFromRotationMatrix(mat);
    euler.setFromQuaternion(quaternion);
  },
  setPosition: pos => {
    const { position } = get();
    position.copy(pos);
  },
  setEuler: eul => {
    const { euler, quaternion } = get();
    const qu = new Quaternion();
    qu.setFromEuler(eul);
    quaternion.copy(qu);
    euler.setFromQuaternion(eul, 'YXZ');
  },
  setQuaternion: quat => {
    const { euler, quaternion } = get();
    quaternion.copy(quat);
    euler.setFromQuaternion(quaternion);
  },
  lookInDirection: dir => {
    const { euler, quaternion } = get();
    const mat = new Matrix4();
    mat.lookAt(zero, dir, up);
    quaternion.setFromRotationMatrix(mat);
    euler.setFromRotationMatrix(mat, 'YXZ');
  },
  lookAt: target => {
    const { position, lookInDirection } = get();
    const v = new Vector3();
    v.subVectors(target, position);
    lookInDirection(v);
  },
  lookAtFrom: (target, origin) => {
    const { setPosition, lookInDirection } = get();
    setPosition(origin);
    const vec = new Vector3();
    vec.subVectors(target, origin);
    lookInDirection(vec);
  },
  getForward: () => {
    const { quaternion } = get();
    const v = new Vector3();
    v.copy(forward);
    return v.applyQuaternion(quaternion);
  },
  updateCamera: params => {
    const { camera, delta, cursorPosition, cursorDown, playerPosition, playerRotation, collisionMesh } = params;
    const { position, quaternion, cursor, orbit, boom, transition, oldOrbit } = get();
    cursor.update(delta, cursorPosition, cursorDown);

    if (oldOrbit.enabled) {
      const dt = Math.min(delta, 1);
      const params = {
        delta: dt,
        position: playerPosition,
        rotation: playerRotation,
        cursorPosition,
        cursorDown,
        collisionMesh,
      };
      oldOrbit.update(params);
    }
    if (orbit.enabled) {
      orbit.orbitOrigin.set(playerPosition[0], playerPosition[1] + 1, playerPosition[2]);
      orbit.update();
    }
    if (boom.enabled) {
      boom.update(delta);
    }
    if (transition.enabled) {
      transition.update(delta);
    }
    const v = new Vector3();
    v.copy(position);

    camera.position.copy(v);
    camera.quaternion.copy(quaternion);

    camera.updateProjectionMatrix();
    camera.updateMatrixWorld();
  },
}));
