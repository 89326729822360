import { getInitialCustomizationConfigurationGen3 } from './configGen3';
import { getInitialCustomizationConfigurationGen1 } from './configGen1';
import { useEventStore } from 'services/EventService';

export const getInitialCustomizationConfiguration = () => {
  const avatarGen = useEventStore.getState().event.avatar.gen;

  if (avatarGen === 3) {
    return getInitialCustomizationConfigurationGen3();
  }

  return getInitialCustomizationConfigurationGen1();
};
