import { useDistrictStore } from 'services/DistrictService';
import { usePlayerStore } from 'services/PlayerService';
import { MODE_DISTRICT, MODE_FITTING, MODE_PRELOAD } from 'services/PlayerService/constants';
import { useEventStore } from 'services/EventService';
import { useContentStore } from 'services/ContentService';
import { useDebugStore } from 'storage/debug';
import React, { useEffect, useMemo, useRef } from 'react';
import { useWindowStore } from 'services/WindowService';
import DistrictCanvas from 'components/Play/DistrictCanvas';
import EmojiFX from 'components/Play/EmojiFX';
import { PinContainer } from 'common/components/PinContainer';
import JoystickControls from 'components/Play/JoystickControls';
import Menu from 'uiLivi/components/Menu';
import FullscreenContent from 'components/FullscreenContent';
import Chat from 'uiLivi/components/Chat';
import { CustomizationUiGen2 } from 'components/CreateAvatar/CustomizationUiGen2';
import { useUserStore } from 'services/UserService';
import { useAvatarStore } from 'services/AvatarService';
import DeviceHint from 'components/Play/DeviceHint';
import VisualLoader from 'components/Play/VisualLoader';
import Stats from 'components/Stats';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { useDeviceStore } from 'services/DeviceService';
import PoweredByLink from 'uiLivi/common/components/PoweredByLink';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';
import { useGlobalHubStore } from 'services/GlobalHubService';
import { CustomizationUi } from 'components/CreateAvatar/CustomizationUi';
import Slack from 'components/Slack';
import { useSlackStore } from 'services/SlackService';

const Container = styled.div`
  height: 100%;
  touch-action: none;

  animation: fadeIn 1s;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

function useDistricts(mapId) {
  const district = useDistrictStore(state => state.districts.find(d => d.room === mapId));
  const loadedDistrict = useDistrictStore(state => state.districts.find(d => d.room === mapId && d.isLoaded));

  useEffect(() => {
    const oldDistrict = useDistrictStore.getState().district;
    if (oldDistrict !== district) {
      if (oldDistrict) {
        usePlayerStore.getState().setMode(null);
        useDistrictStore.getState().leaveDistrict(oldDistrict);
      }

      useDistrictWarmupStore.setState({ nextMapId: mapId });
      useDistrictWarmupStore.getState().setPhase(WarmupPhase.IDLE);

      usePlayerStore.getState().setMode(MODE_PRELOAD);
      useDistrictStore.getState().initDistrict(district);
    }
  }, [mapId]);

  useEffect(() => {
    const { enterDistrict } = useDistrictStore.getState();
    if (loadedDistrict && !loadedDistrict.entered) {
      const { setMode } = usePlayerStore.getState();
      enterDistrict(loadedDistrict);
      setMode(MODE_DISTRICT);
    }
  }, [loadedDistrict]);
}

// mounted & unmounted by Routes
// has event & login
export default function PlayDistrict({ mapId }) {
  const district = useDistrictStore(state => state.district);
  const slackName = useSlackStore(state => state.open?.name);
  const avatarGen = useEventStore(state => state.event.avatar.gen);
  const hasDistrict = useDistrictStore.getState().district && useDistrictStore.getState().district.room === mapId;
  const isFitting = usePlayerStore(state => state.mode === MODE_FITTING);
  const useAvatars = useEventStore(state => state.event.useAvatars);
  const content = useContentStore(state => state.activeContent);
  const fullscreenContentInFocus = content && (content.type.isFullscreen || content.contentType.isFullscreen);
  const loadedDistrict = useDistrictStore(state => state.districts.find(d => d.room === mapId && d.isLoaded));
  const debugStore = useDebugStore();
  const device = useDeviceStore(state => state.device);
  const showUI = !debugStore.getUiDisabled();
  const { uiLivi } = useDebugStore(state => state.getUi());

  useDistricts(mapId);

  const containerRef = useRef();

  //update cursor pointer from useWindowStore hover without rerender
  useEffect(() => {
    let hover = useWindowStore.getState().hover;
    return useWindowStore.subscribe(() => {
      const state = useWindowStore.getState();
      if (state.hover !== hover) {
        hover = state.hover;
        if (showUI && containerRef.current) {
          containerRef.current.style['cursor'] = hover ? 'pointer' : 'default';
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!showUI && containerRef.current) {
      containerRef.current.style['cursor'] = 'none';
    } else {
      const state = useWindowStore.getState();
      if (showUI && containerRef.current) {
        containerRef.current.style['cursor'] = state.hover ? 'pointer' : 'default';
      }
    }
  }, [showUI]);

  const uiProps = useMemo(
    () => ({
      initial: false,
      onDone: () => {
        useUserStore.getState().changeAppearance(useAvatarStore.getState().getAppearance());
        useGlobalHubStore.getState().refreshGlobalPlayer();
        usePlayerStore.getState().setMode(MODE_DISTRICT);
      },
    }),
    []
  );

  return (
    <Container ref={containerRef}>
      {hasDistrict && (
        <>
          <DistrictCanvas key={mapId} district={district} />
          {fullscreenContentInFocus && <EmojiFX />}
          <PinContainer isFitting={isFitting} />
        </>
      )}

      {showUI && !isFitting && useAvatars && (device.isMobile || device.isTablet) && <JoystickControls />}
      {uiLivi && <Menu />}
      <FullscreenContent />
      {showUI && slackName && <Slack key={slackName} />}
      {uiLivi && !isFitting && <Chat />}
      {isFitting && <>{avatarGen === 2 ? <CustomizationUiGen2 {...uiProps} /> : <CustomizationUi {...uiProps} />}</>}
      <DeviceHint />
      <VisualLoader />
      {debugStore.getStatsEnabled() && <Stats />}
      {showUI && loadedDistrict && loadedDistrict.entered && <Modal />}
      {!isFitting && uiLivi && <PoweredByLink />}
    </Container>
  );
}
