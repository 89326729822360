import { useAvatarStore } from 'services/AvatarService';
import { Color, LinearEncoding, MeshPhysicalMaterial, MeshStandardMaterial } from 'three';
import { useEnvironmentStore } from '../Environment/store';

function updateConfigurationGen3(clonedScene, appearance, dirLightWithShadow) {
  try {
    const appearanceValues = useAvatarStore.getState().computeAppearanceValues(appearance);

    // console.log('updateConfigurationGen3', appearanceValues, clonedScene);

    const enabledObjects = [
      'avatar_head',
      'avatar_hands',
      ...Object.values(appearanceValues).map(value => value.style?.default?.name),
    ];

    // collect material switches in configuration
    const materialSwitches = {};
    Object.values(appearanceValues).forEach(value => {
      if (value.style?.default?.materialSwitches) {
        materialSwitches[value.style.default.name] = value.style.default.materialSwitches;
      }
    });

    // console.log('materialSwitches', materialSwitches);

    const enable = function(o) {
      while (o !== clonedScene) {
        o.visible = true;
        o = o.parent;
      }
    };

    clonedScene.traverse(object => {
      if (!object.isBone) {
        object.dontRenderIfInvisible = true;
        object.visible = false;
      }

      if (object.isMesh) {
        // due to glb export, some objects are made of multiple parts, and include a _N suffix,
        // which is removed to match the name in our config.
        let normalizedName = object.name;
        if (normalizedName[normalizedName.length - 2] === '_') {
          normalizedName = normalizedName.substring(0, normalizedName.length - 2);
        }

        if (!enabledObjects.includes(normalizedName)) return;
        enable(object);

        // material switches
        if (object.orgMaterial) object.material = object.orgMaterial;

        const objectMaterialSwitches = materialSwitches[normalizedName];
        if (objectMaterialSwitches) {
          // console.log('Found switches for object', object, ' switches:', objectMaterialSwitches);

          objectMaterialSwitches.forEach(materialSwitch => {
            const { from, to } = materialSwitch;
            const doesFromApply = from === undefined || object.material.name.toLowerCase().includes(from);
            if (doesFromApply) {
              const objectWithMaterial = clonedScene.getObjectByName(to);
              // console.log('switching', object.material, 'to', objectWithMaterial.material);
              object.orgMaterial = object.material;
              object.material = objectWithMaterial.material;
            }
          });
        }

        if (object.material.map) {
          object.material.map.anisotropy = 16;
        }

        if (object.material.roughnessMap) {
          object.material.roughnessMap.anisotropy = 16;
        }

        if (object.material.normalMap) {
          object.material.normalMap.anisotropy = 16;
        }

        // colors
        const material = object.material.name.toLowerCase();

        const sheen = dirLightWithShadow.sheenIntensity;
        const sheenRoughness = dirLightWithShadow.sheenRoughness;

        if (material.startsWith('hair')) {
          const clonedMaterial = object.material.clone();
          const newMaterial = new MeshPhysicalMaterial(clonedMaterial);
          clonedMaterial.copy(newMaterial);

          newMaterial.name = object.material.name;
          newMaterial.color = new Color(appearanceValues.hair.color.default);
          newMaterial.sheen = sheen;
          newMaterial.sheenColor = new Color(dirLightWithShadow.color);
          newMaterial.sheenRoughness = sheenRoughness;

          object.material = newMaterial;
        }

        if (material.startsWith('beard') || material.startsWith('mustache')) {
          const clonedMaterial = object.material.clone();
          const newMaterial = new MeshPhysicalMaterial(clonedMaterial);

          newMaterial.name = object.material.name;
          newMaterial.color = new Color(appearanceValues.facial_hair.color.default);
          newMaterial.sheen = sheen;
          newMaterial.sheenColor = new Color(dirLightWithShadow.color);
          newMaterial.sheenRoughness = sheenRoughness;

          object.material = newMaterial;
        }

        if (material === 'skin_mat') {
          const { color, metalness, roughness, iridescence } = appearanceValues.skin.material.default;
          const mat = iridescence > 0 ? new MeshPhysicalMaterial() : new MeshStandardMaterial();

          mat.name = 'skin_mat';
          mat.color = new Color(color);
          mat.roughness = roughness;
          mat.metalness = metalness;

          if (mat instanceof MeshPhysicalMaterial) {
            mat.iridescence = iridescence;
          }

          object.material = mat;
        }

        if (material === 'glasses_iridescence_mat') {
          const mat = new MeshPhysicalMaterial();
          mat.color = object.material.color;
          mat.color = new Color(0.0, 0.0, 0.0);
          mat.roughness = 0;
          mat.metalness = 1;
          mat.iridescence = 1;
          mat.iridescenceIOR = 1.5;
          object.material = mat;
        }
      }
    });
    clonedScene.visible = true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('updateConfiguration', e);
  }
}
export { updateConfigurationGen3 };
