import React, { useMemo, useState } from 'react';
import { Container, OptionContainer, OptionTab, OptionTabs, Pager, PagerButton } from './styles';
import Option from './Option';
import { useTranslate } from 'services/TranslationService/hooks';

export default function OptionsPanel({ categoryId, panelIndex, panel }) {
  const MAX_OPTIONS_PER_PAGE = 15;
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [selectedTopPageIndex, setSelectedTopPageIndex] = useState(0);
  const optionPages = useMemo(() => {
    const optionPages = [];
    const filteredOptions = panel.options.filter(o => o.use !== false);
    filteredOptions.forEach((o, i) => {
      const pageIndex = Math.floor(i / MAX_OPTIONS_PER_PAGE);
      if (!optionPages[pageIndex]) optionPages[pageIndex] = [];
      optionPages[pageIndex].push({ optionIndex: i, option: o });
    });
    return optionPages;
  }, [panel.options]);

  const showPager = optionPages.length > 1;
  const showTopColorTabs = categoryId === 'top' && panelIndex === 1;
  const translate = useTranslate();

  return (
    <>
      {showTopColorTabs && (
        <OptionTabs>
          <OptionTab
            active={selectedTopPageIndex === 0}
            onClick={() => {
              setSelectedTopPageIndex(0);
            }}
          >
            {translate('avatar.tab.primary') || 'Primary'}
          </OptionTab>
          <OptionTab
            active={selectedTopPageIndex === 1}
            onClick={() => {
              setSelectedTopPageIndex(1);
            }}
          >
            {translate('avatar.tab.secondary') || 'Secondary'}
          </OptionTab>
        </OptionTabs>
      )}
      <Container>
        <OptionContainer>
          {panel.options &&
            optionPages[selectedPageIndex].map((optionPage, i) => {
              const selected = [];
              panel.selections.forEach(selection => {
                if (selection.index === optionPage.optionIndex) selected.push(selection.name);
              });
              return (
                <Option
                  key={optionPage.optionIndex}
                  option={optionPage.option}
                  optionIndex={optionPage.optionIndex}
                  appearIndex={i}
                  selected={selected}
                  categoryId={categoryId}
                  panelIndex={panelIndex}
                  panelType={panel.type}
                  selectedTopPageIndex={selectedTopPageIndex}
                />
              );
            })}
        </OptionContainer>
        {showPager && (
          <Pager>
            <PagerButton
              left
              active={selectedPageIndex > 0}
              onClick={() => {
                setSelectedPageIndex(selectedPageIndex - 1);
              }}
            />
            <PagerButton
              active={selectedPageIndex < optionPages.length - 1}
              onClick={() => {
                setSelectedPageIndex(selectedPageIndex + 1);
              }}
            />
          </Pager>
        )}
      </Container>
    </>
  );
}
