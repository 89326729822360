import create from 'utilities/zustand/create';
import debugStore from 'storage/debug';
import React from 'react';

const RendererDatGui = React.lazy(() => import('three/Renderer/RendererDatGui'));
const EnvironmentDatGui = React.lazy(() => import('../../Play/Environment/EnvironmentDatGui'));
const ThreeInspector = React.lazy(() => import('../ThreeInspector'));
const AudioInspector = React.lazy(() => import('../AudioInspector'));

export default EnvironmentDatGui;

const stack = [
  { label: 'Off', gui: null },
  { label: 'Renderer', gui: <RendererDatGui /> },
  { label: 'Environment', gui: <EnvironmentDatGui /> },
  { label: 'Three Inspector', gui: <ThreeInspector /> },
  { label: 'Audio Inspector', gui: <AudioInspector /> },
];

export const useDatGuiStackStore = create((set, get) => ({
  stack,
  selectedIndex: debugStore.getSelectedDatGuiIndex() % stack.length,
  setSelectedIndex: index => {
    const selectedIndex = index % get().stack.length;
    debugStore.setSelectedDatGuiIndex(selectedIndex);
    set({ selectedIndex });
  },
}));
