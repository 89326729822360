import create from 'utilities/zustand/create';
import userStore from 'storage/user';
import { useContentStore } from 'services/ContentService';
import { Howler } from 'howler';
import { generateUUID } from 'three/src/math/MathUtils';

const connectOrg = AudioNode.prototype.connect;
const disconnectOrg = AudioNode.prototype.disconnect;
AudioNode.prototype.connect = function(...args) {
  const t = args[0];
  if (!this.uuid) {
    this.uuid = generateUUID();
  }
  if (!t.uuid) {
    t.uuid = generateUUID();
  }

  // console.log('add', this, 'to', t);

  // remove from last parent
  if (this.p) {
    const index = this.p.c.indexOf(this);
    if (index !== -1) {
      this.p.c.splice(index, 1);
    }
    this.p = null;
  }

  if (!t.c) t.c = [];
  t.c.push(this);
  this.p = args[0];

  connectOrg.apply(this, args);
};
AudioNode.prototype.disconnect = function(...args) {
  // console.log('remove', this, 'from', this.p, args);
  const index = this.p.c.indexOf(this);
  if (index !== -1) {
    this.p.c.splice(index, 1);
  }
  this.p = null;
  disconnectOrg.apply(this, args);
};

export const useSoundStore = create((set, get) => ({
  isPlayAllowed: false,
  enabled: userStore.getSoundEnabled(),
  videos: [],

  init: () => {
    const autoPlay = () => {
      // window.removeEventListener('touchstart', autoPlay);
      // window.removeEventListener('mousedown', autoPlay);
      // window.removeEventListener('keydown', autoPlay);
      get().allowPlay();
    };
    // window.addEventListener('touchstart', autoPlay);
    window.addEventListener('mousedown', autoPlay);
    window.addEventListener('keydown', autoPlay);

    const video = document.createElement('video');
    video.style.position = 'fixed';
    video.style.left = '0px';
    video.style.top = '-1000px';
    video.style.width = '100px';
    video.style.height = '100px';
    video.style.zIndex = '0';
    video.playsInline = true;
    video.muted = false;
    video.autoPlay = false;
    video.controls = true;
    video.src = 'https://assets.virtual-experience.demodern.com/events/demo/contents/Lobby_Outdoor_Presentation_1.mp4';
    document.body.append(video);
    set({ iPhoneHackVideo: video });

    useContentStore.subscribe(() => {
      get().adjustVolume();
    });
  },

  allowPlay: () => {
    if (!get().isPlayAllowed) {
      set({ isPlayAllowed: true });

      get().iPhoneHackVideo.play();
      setTimeout(() => {
        get().iPhoneHackVideo.pause();
        get().iPhoneHackVideo.remove();
      }, 0);
    }

    get().unmute();
  },

  unmute: () => {
    get().videos.forEach(video => {
      video.video.muted = false;
    });
  },

  playVideo: (video, position) => {
    const gain = Howler.ctx.createGain();
    gain.gain.setValueAtTime(0.5, Howler.ctx.currentTime);
    gain.connect(Howler.masterGain);

    const panner = Howler.ctx.createPanner();
    panner.coneInnerAngle = 360;
    panner.coneOuterAngle = 360;
    panner.coneOuterGain = 0;
    panner.distanceModel = 'exponential';
    panner.maxDistance = 10000;
    panner.refDistance = 20;
    panner.rolloffFactor = 10;
    panner.panningModel = 'HRTF';
    panner.positionX.setValueAtTime(position.x, Howler.ctx.currentTime);
    panner.positionY.setValueAtTime(position.y, Howler.ctx.currentTime);
    panner.positionZ.setValueAtTime(position.z, Howler.ctx.currentTime);
    panner.connect(gain);

    const source = Howler.ctx.createMediaElementSource(video);
    source.connect(panner);

    const videos = get().videos;
    videos.push({ video });
    set({ videos });

    if (get().isPlayAllowed) {
      video.muted = false;
    }
    get().adjustVolume();
  },

  removeVideo: video => {
    const videos = get().videos;
    const videoIndex = videos.findIndex(v => {
      return v.video === video;
    });

    if (videoIndex > -1) {
      videos.splice(videoIndex, 1); // 2nd parameter means remove one item only
    }
    set({ videos });
  },

  setEnabled: active => {
    set({ enabled: active });
    userStore.setSoundEnabled(get().enabled);
    get().adjustVolume();
  },

  toggle: () => {
    set({ enabled: !get().enabled });
    userStore.setSoundEnabled(get().enabled);
    get().adjustVolume();
  },

  adjustVolume: () => {
    const { videos } = get();
    videos.forEach(video => {
      if (video.video) {
        if (useContentStore.getState().activeContent) {
          video.video.volume = 0;
        } else {
          video.video.volume = get().enabled ? 1 : 0;
        }
      }
    });
  },
}));
