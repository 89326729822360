import { useFrame, useLoader } from '@react-three/fiber';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useContentStore } from 'services/ContentService';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { useLightmaps } from 'services/MaterialService/hooks';
import { useWindowStore } from 'services/WindowService';
import {
  AdditiveBlending,
  AnimationMixer,
  Box3,
  BoxGeometry,
  LinearFilter,
  LinearMipMapLinearFilter,
  MeshBasicMaterial,
  NormalBlending,
  Object3D,
  sRGBEncoding,
  Vector3,
} from 'three';
import { useDebugAssetVersion } from 'services/DebugAssetVersion';
import { useAssetDetails } from 'services/AssetDetailService';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import fromCdn from 'utilities/cdn';
import { useEnvironmentStore } from 'components/Play/Environment/store';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils';
import ProductAudio from '../ProductAudio';
import { useSlackStore } from 'services/SlackService';
import { mapContent } from 'services/ContentService/utils';
import Label from './Label';
import { useGlobalHubStore } from 'services/GlobalHubService';

const configureLightMap = lightMap => {
  lightMap.flipY = false;
  lightMap.generateMipmaps = true;
  lightMap.anisotropy = 16;
  lightMap.minFilter = LinearMipMapLinearFilter;
  lightMap.magFilter = LinearFilter;
  lightMap.encoding = sRGBEncoding;
};

function useGLTFOrEmpty(product) {
  const debugAssetVersion = useDebugAssetVersion(state => state.version);
  const isDetailMobile = useAssetDetails(state => state.isDetailMobile);
  const versionParam = `?v=${debugAssetVersion}`;

  if (product.mesh) {
    return useLoader(
      GLTFLoader,
      fromCdn(
        product.meshMobile
          ? isDetailMobile
            ? product.meshMobile + versionParam
            : product.mesh + versionParam
          : product.mesh + versionParam
      )
    );
  } else {
    return useMemo(() => {
      const scene = new Object3D();
      scene.userData = { hideInDebug: true };
      return { scene, animations: [] };
    }, []);
  }
}

const Product = ({ product }) => {
  const environmentConfiguration = useEnvironmentStore(state => state.environmentConfiguration);
  const setWindowStoreHover = useWindowStore(state => state.setHover);
  const globalUsers = useGlobalHubStore(state => state.users);

  const productRef = useRef(null);
  const groupRef = useRef(null);
  const primitiveRef = useRef(null);
  const labelGroupRef = useRef(null);
  const randomAnimationOffset = useRef(Math.random());
  const gltf = useGLTFOrEmpty(product);
  const lightmaps = useLightmaps();
  const [sceneData, setSceneData] = useState(null);

  //mount
  useEffect(() => {
    const renderOrder = product.renderOrder ? Number(product.renderOrder) : 0;
    const objectScene = clone(gltf.scene);
    objectScene.audio = product.audio;
    objectScene.traverse(o => {
      if (o.isMesh) {
        o.renderOrder = renderOrder;
      }
      if (o.material) {
        //lightmaps
        const lightmap = lightmaps.find(lm => o.name.includes(lm.tag));
        if (lightmap) {
          configureLightMap(lightmap.texture);
          o.material.lightMap = lightmap.texture;
        }
        o.material.depthWrite = true;
        o.castShadow = true;
        o.receiveShadow = true;

        if (product.shadow) {
          o.castShadow = product.shadow.castShadow;
          o.receiveShadow = product.shadow.receiveShadow;
        }

        if (o.material.map) {
          o.material.map.minFilter = LinearMipMapLinearFilter;
          o.material.map.magFilter = LinearFilter;
          o.material.map.anisotropy = 16;
        }

        if (o.material.name.includes('_cutout')) {
          o.material.transparent = false;
          o.material.alphaTest = 0.5;
        }

        if (o.material.name.includes('_nocutout')) {
          o.material.transparent = true;
          o.material.alphaTest = 0;
        }

        if (o.material.name.includes('_add')) {
          o.material.blending = AdditiveBlending;
          o.material.transparent = true;
          o.material.depthWrite = false;
          o.receiveShadow = false;
          o.castShadow = false;
        }

        if (o.material.name === 'cloud') {
          o.material.blending = NormalBlending;
          o.material.transparent = true;
          o.material.depthWrite = false;
          o.receiveShadow = false;
          o.castShadow = false;
        }
      }
    });

    const bb = new Box3().setFromObject(objectScene);
    const boxGeom = new BoxGeometry(bb.max.x - bb.min.x, bb.max.y - bb.min.y, bb.max.z - bb.min.z);
    const boxMat = new MeshBasicMaterial({ color: 0xffffff, visible: false });
    const boxPos = new Vector3(0, bb.max.y * 0.5, 0);

    const { animations } = gltf;
    const animationMixer = new AnimationMixer();
    if (animations.length > 0) {
      animations.forEach(animation => {
        const action = animationMixer.clipAction(animation, objectScene);
        action.setLoop(true);
        action.play();
      });
    }

    const content = {
      type: CONTENT_TYPE.PRODUCT,
      headline: product.headline || 'Product',
      pin: {
        normal: [0.6, 0, -0.76],
        offset: [0, 0, 0],
        scale: 0.3,
      },
      product: {
        ...product,
        object: clone(objectScene),
      },
    };

    setSceneData({
      animationMixer,
      objectScene,
      boxGeom,
      boxMat,
      boxPos,
      content,
    });
  }, [gltf]);

  useEffect(() => {
    if (!sceneData) return;
    const { objectScene } = sceneData;
    objectScene.traverse(o => {
      if (o.material) {
        if (o.material.blending !== AdditiveBlending) {
          o.material.envMapIntensity = environmentConfiguration.envMap.intensity;
        } else {
          o.material.envMapIntensity = 1;
        }
      }
    });
  }, [sceneData, environmentConfiguration]);

  // update animation mixer
  useFrame((c, delta) => {
    const animationMixer = sceneData?.animationMixer;
    if (animationMixer) {
      animationMixer.update(delta);
    }
  });

  const splitByComma = string => {
    if (!string) {
      return [0, 0, 0];
    }
    const splits = string.split(',');
    if (splits.length === 1) {
      splits[1] = splits[0];
      splits[2] = splits[0];
    }
    return splits.map(x => Number(x));
  };

  const splitByCommaAndConvertDeg2Rag = string => {
    return splitByComma(string).map(x => (Number(x) * Math.PI) / 180);
  };

  const autoRotation = splitByCommaAndConvertDeg2Rag(product.autoRotation);

  useFrame((context, deltaTime) => {
    if (!productRef.current || autoRotation == null) return;
    productRef.current.rotation.x += autoRotation[0] * deltaTime;
    productRef.current.rotation.y += autoRotation[1] * deltaTime;
    productRef.current.rotation.z += autoRotation[2] * deltaTime;
  });

  useFrame(context => {
    if (!primitiveRef.current || product.hover == null) return;
    const { radius, duration } = product.hover;
    const y =
      Math.sin(
        randomAnimationOffset.current * Math.PI * 2 +
          (context.clock.elapsedTime * Math.PI * 2) / (duration !== null ? duration : 1)
      ) * (radius !== null ? radius : 1);
    primitiveRef.current.position.y = y;
    if (labelGroupRef.current) labelGroupRef.current.position.y = y;
  });

  if (!sceneData) return null;

  const { objectScene, boxPos, boxGeom, boxMat, content: sceneContent } = sceneData;
  const productContent = product.content && mapContent(product.content);
  const hasContent = !!productContent;
  const hasSlack = !!sceneContent.product.slack;
  const hideBecausePlayerIsHere =
    hasSlack &&
    !!Object.values(globalUsers).find(gu => {
      return gu.forename == sceneContent.product.slack.name && gu.surname == sceneContent.product.slack.lastName;
    });
  if (hideBecausePlayerIsHere) return null;
  const enableClickMesh = hasContent || hasSlack;
  return (
    <group name={product.headline} position={splitByComma(product.location)} ref={groupRef}>
      <group
        name="Product"
        rotation={splitByCommaAndConvertDeg2Rag(product.rotation)}
        scale={splitByComma(product.scale)}
        ref={productRef}
        audio={product.audio}
      >
        <primitive name="primitive" object={objectScene} ref={primitiveRef} />
        {enableClickMesh && (
          <mesh
            name="clickMesh"
            position={boxPos}
            geometry={boxGeom}
            material={boxMat}
            onPointerOver={e => {
              e.stopPropagation();
              setWindowStoreHover(true);
            }}
            onPointerOut={() => {
              setWindowStoreHover(false);
            }}
            onClick={e => {
              e.stopPropagation();
              if (hasSlack) useSlackStore.getState().openUi(sceneContent.product.slack);
              else if (productContent) useContentStore.getState().setActiveContent(productContent);
            }}
          />
        )}
        {product.audio && <ProductAudio audio={product.audio} groupRef={groupRef} />}
      </group>
      {product.label && (
        <group name="Label" ref={labelGroupRef}>
          <Label label={product.label} globalPosition={splitByComma(product.location)} />
        </group>
      )}
    </group>
  );
};

export default Product;
