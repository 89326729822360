import React, { useEffect, useMemo } from 'react';

import { useUserStore } from 'services/UserService';
import { useContentStore } from 'services/ContentService';
import { useWindowStore } from 'services/WindowService';
import { useChatStore } from 'services/ChatService';
import { MainMenuContainer, MenuContainer, MenuItemContainer } from './styles';
import SubMenu from './SubMenu';
import BurgerButton from './BurgerButton';
import getItems from './menuItems';
import SoundButton from 'uiLivi/components/SoundButton';
import CloseButton from 'common/components/CloseButton';
import { useDebugStore } from 'storage/debug';
import { useMenuStore } from 'services/MenuService';
import { useCameraStore } from 'services/CameraService';
import { usePlayerStore } from 'services/PlayerService';
import { MODE_FITTING } from 'services/PlayerService/constants';
import MenuItem from './MenuItem';
import { useDeviceStore } from 'services/DeviceService';

export default function Menu() {
  const debugStore = useDebugStore();
  const hideMenuBecauseOfContent = useContentStore(
    state => state.activeContent && (state.activeContent.type.hideMenu || state.activeContent.contentType.hideMenu)
  );
  const isFitting = usePlayerStore(state => state.mode === MODE_FITTING);
  const hideMenuBecauseIntro = useCameraStore(state => state.mode !== 'free');
  const selectedUserId = useUserStore(state => state.selectedUserId);
  const user = useUserStore(state => state.user);
  const device = useDeviceStore(state => state.device);
  const isTouchDevice = useMemo(() => device.isMobileOnly || device.isTablet, [device]);
  const isMobile = useWindowStore(state => state.isMobile);
  const isChatOpen = useChatStore(state => state.isActive);
  const burgerOpen = useMenuStore(state => state.burgerOpen);
  const menuItems = useMenuStore(state => state.items);
  const language = useUserStore(state => state.user.language);
  const highlightedMenuItem = useMenuStore(state => state.highlightedMenuItem);

  useEffect(() => {
    const initialItemState = getItems(user);
    useMenuStore.setState({ items: initialItemState });
  }, [language]);
  useEffect(() => {
    if (isChatOpen) useMenuStore.getState().setBurgerOpen(false);
  }, [isChatOpen]);
  useEffect(() => {
    if (selectedUserId !== null) {
      const newMenuItems = menuItems.map(item => {
        return { ...item, active: item.id === 'profile' };
      });
      useMenuStore.setState({ items: newMenuItems });
      if (isMobile) useMenuStore.getState().setBurgerOpen(true);
    }
  }, [selectedUserId]);
  const hideMenu = hideMenuBecauseOfContent || hideMenuBecauseIntro || isFitting;
  useEffect(() => {
    if (hideMenu) {
      deselectMenuItems();
    } else {
      const initialItemState = getItems(user);
      useMenuStore.setState({ items: initialItemState });
    }
  }, [hideMenu, language]);

  const getDeselectedMenuItems = () => menuItems.map(mI => (!mI.active ? mI : { ...mI, active: false }));

  const deselectMenuItems = () => {
    useMenuStore.setState({ items: getDeselectedMenuItems() });
  };

  const selectMenuItem = id => {
    const newMenuItems = getDeselectedMenuItems().map(mI => {
      const wasActive = menuItems.find(mI => mI.id === id).active;
      const active = mI.id === id && !wasActive;
      return { ...mI, active };
    });
    useMenuStore.setState({ items: newMenuItems });
  };

  const showMenu = !hideMenu && ((isMobile && burgerOpen) || !isMobile);
  const showBurger = !burgerOpen && !hideMenu && isMobile && !isChatOpen;
  const hasActiveMenuItem = menuItems.find(mI => mI.active);

  return (
    <MenuContainer aboveAll={highlightedMenuItem !== null}>
      <MainMenuContainer show={showMenu}>
        {isMobile && (
          <>
            {showMenu && hasActiveMenuItem && (
              <CloseButton
                black
                isBackButton
                position={{ top: 0, left: 0 }}
                handler={() => {
                  deselectMenuItems();
                }}
              />
            )}
            <CloseButton
              black={hasActiveMenuItem}
              position={{ top: 0, right: 0 }}
              handler={() => {
                if (burgerOpen) useMenuStore.getState().setBurgerOpen(false);
                deselectMenuItems();
              }}
            />
          </>
        )}
        <MenuItemContainer>
          {menuItems.map((mI, index) => {
            if (!debugStore.getDeveloperContentEnabled() && mI.isDevContent) return null;
            const active = highlightedMenuItem === mI.id || mI.active;
            return <MenuItem key={index} active={active} mI={mI} clickHandler={selectMenuItem} />;
          })}
          <SoundButton />
        </MenuItemContainer>
      </MainMenuContainer>
      <SubMenu
        show={showMenu}
        menuItem={menuItems.find(mI => mI.active)}
        closeMenu={() => {
          deselectMenuItems();
          useMenuStore.getState().setBurgerOpen(false);
        }}
      />
      <BurgerButton
        show={showBurger}
        handleClick={() => {
          useMenuStore.getState().setBurgerOpen(true);
        }}
      />
    </MenuContainer>
  );
}
