import { H4 } from 'common/text';
import RenderQualityPanel from 'uiLivi/components/Menu/SubMenu/DevMenu/RenderQualityPanel';
import DatGuiPanel from './DatGuiPanel';
import { useDebugStore } from 'storage/debug';
import MaterialsPanel from './MaterialsPanel';
import { Container, SecondLevelLinkItem } from './styles';
import HBFLayout from 'common/components/HBFLayout';
import ToggleButton from 'common/components/ToggleButton';
import { useLiveStreamStore } from 'services/LiveStreamService';
import { useProfilingStore } from 'services/ProfilingService';
import { marginTopAndButton } from '../../styles';
import { closeButtonSize } from '../styles';
import { Button } from 'common/components/Button';
import { useCubemapRGBMDebugStore } from 'components/Play/Environment/CubemapRGBM';
import UiPanel from './UiPanel';
import { useAssetDetails } from 'services/AssetDetailService';
import { useDebugAssetVersion } from '../../../../../services/DebugAssetVersion';
import { useEventStore } from '../../../../../services/EventService';
import { useSlackStore } from 'services/SlackService';
import { useUserStore } from 'services/UserService';
import { useGpuDetect } from 'services/GpuDetectService';

function DevMenu() {
  const debugStore = useDebugStore();
  const userName = useUserStore(state => state.user.forename);
  const isDetailMobile = useAssetDetails(state => state.isDetailMobile);
  const marks = useProfilingStore(state => state.marks);
  const gpuDetect = useGpuDetect(state => state);
  console.log(gpuDetect);
  const body = (
    <Container>
      <H4>Options</H4>
      <div>
        Show Socket Messages:
        <ToggleButton
          active={debugStore.getSocketDebugEnabled()}
          onToggle={value => {
            debugStore.setSocketDebugEnabled(value);
          }}
        />
      </div>
      <div>
        Show Tracking Actions:
        <ToggleButton
          active={debugStore.getTrackingDebugEnabled()}
          onToggle={value => {
            debugStore.setTrackingDebugEnabled(value);
          }}
        />
      </div>
      <div>
        Minimal 3D:
        <ToggleButton
          active={debugStore.getMinimal3d()}
          onToggle={value => {
            debugStore.setMinimal3d(value);
          }}
        />
      </div>
      <div>
        Enable Stats:
        <ToggleButton
          active={debugStore.getStatsEnabled()}
          onToggle={value => {
            debugStore.setStatsEnabled(value);
          }}
        />
      </div>
      <div>
        Enable Transparent Loader:
        <ToggleButton
          active={debugStore.getTransparentLoaderEnabled()}
          onToggle={value => {
            debugStore.setTransparentLoaderEnabled(value);
          }}
        />
      </div>
      <div>
        Enable Ambient Music:
        <ToggleButton
          active={debugStore.getAmbientMusicEnabled()}
          onToggle={value => {
            debugStore.setAmbientMusicEnabled(value);
          }}
        />
      </div>
      <div>
        Show Many Avatars:
        <ToggleButton
          active={debugStore.getShowManyAvatars()}
          onToggle={value => {
            debugStore.setShowManyAvatars(value);
          }}
        />
      </div>
      <div>
        Speed Hack:
        <ToggleButton
          active={debugStore.getSpeedHack()}
          onToggle={value => {
            debugStore.setSpeedHack(value);
          }}
        />
      </div>
      <div>
        Debug Environment Map:
        <ToggleButton
          active={debugStore.getDebugEnvironmentEnabled()}
          onToggle={value => {
            debugStore.setDebugEnvironmentEnabled(value);
          }}
        />
        <div style={{ padding: 10 }}>
          <Button
            onClick={() => {
              const input = document.createElement('input');
              input.type = 'file';
              input.accept = '*';
              document.body.appendChild(input);
              input.onchange = e => {
                document.body.removeChild(input);
                const file = e.target.files[0];
                const url = URL.createObjectURL(file);
                useCubemapRGBMDebugStore.setState({ exrDebugFile: url });
              };
              input.click();
            }}
          >
            Open EXR
          </Button>
        </div>
      </div>
      <Button
        onClick={async () => {
          //two times to get lates strapi version
          await useEventStore.getState().init();
          await useEventStore.getState().init();
          useDebugAssetVersion.setState({ version: Math.random() });
        }}
      >
        Update Strapi and set random Asset Version
      </Button>
      <H4>UI</H4>
      <UiPanel />
      <H4>Render Quality</H4>
      <RenderQualityPanel showDevOptions={true} />
      <H4>GPU Detect</H4>
      <a href="https://pmndrs.github.io/detect-gpu/" target="_blank">
        Open Test
      </a>
      <div>tier: {gpuDetect.tier}</div>
      <div>fps: {gpuDetect.fps}</div>
      <div>
        quality:
        <code> fps &gt; 60 && tier &gt;= 3 ? high : tier &lt;= 2 ? low : normal</code>
      </div>
      <H4>Asset Details</H4>
      <div>
        Asset Details isMobile: {useAssetDetails.getState().isDetailMobile ? 'true' : 'false'}
        <ToggleButton
          active={isDetailMobile}
          onToggle={value => {
            useAssetDetails.getState().changeAssetDetail(value);
          }}
        />
        Recommendation isMobile: {useAssetDetails.getState().isDetailMobileRecommendation ? 'true' : 'false'}
      </div>
      <H4>Dat GUIs</H4>
      <DatGuiPanel />
      <H4>Materials</H4>
      <MaterialsPanel />
      <H4>LiveStream</H4>
      Force Streams Offline:
      <ToggleButton
        onToggle={e => {
          useLiveStreamStore.setState({ override: e });
        }}
      />
      <H4>i18n</H4>
      Render translation keys:
      <ToggleButton
        active={debugStore.getRenderTranslationKeysEnabled()}
        onToggle={value => {
          debugStore.setRenderTranslationKeysEnabled(value);
        }}
      />
      <H4>Links</H4>
      <SecondLevelLinkItem exact={true} main={1} to={'/config'}>
        Config
      </SecondLevelLinkItem>
      <H4>Profiling</H4>
      {marks.map((m, i) => {
        return (
          <div key={i}>
            {m.message} {m.time}
          </div>
        );
      })}
    </Container>
  );

  return <HBFLayout body={body} fullHeightMinus={marginTopAndButton * 2 + closeButtonSize} />;
}

export default DevMenu;
