import create from 'utilities/zustand/create';

const presets = {
  lobby: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#ffecbb',
      intensity: 0.6,
      sheenIntensity: 0.4,
      sheenRoughness: 0.25,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: true,
      azimuth: -0.929,
      inclination: -1.039,
      mixValue: 0.248,
    },
    water: {
      enabled: true,
      seaLevel: -5.5,
    },
    lightmap: {
      intensity: 0.85,
    },
    envMap: {
      intensity: 0.8,
      intensityLightMaps: 0.1,
    },
  },
  auditorium: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#92BFF1',
      intensity: 0.8,
      sheenIntensity: 0.4,
      sheenRoughness: 0.25,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: false,
      azimuth: -1.559,
      inclination: 0.6,
      mixValue: 0.5,
    },
    water: {
      enabled: false,
      seaLevel: -5.5,
    },
    lightmap: {
      intensity: 1,
    },
    envMap: {
      intensity: 0.4,
      intensityLightMaps: 0.15,
    },
  },
  fittingRoom: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#ffecbb',
      intensity: 0.6,
      sheenIntensity: 0.4,
      sheenRoughness: 0.25,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: false,
      azimuth: 0.787,
      inclination: 0.4,
      mixValue: 0.248,
    },
    water: {
      enabled: false,
      seaLevel: -5.5,
    },
    lightmap: {
      intensity: 1,
    },
    envMap: {
      intensity: 0.7,
      intensityLightMaps: 0.15,
    },
  },
  showroom: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#ffecbb',
      intensity: 0.6,
      sheenIntensity: 0.4,
      sheenRoughness: 0.25,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: true,
      azimuth: 1.94,
      inclination: 0.814,
      mixValue: 0.0,
    },
    water: {
      enabled: true,
      seaLevel: -0.6,
    },
    lightmap: {
      intensity: 1,
    },
    envMap: {
      intensity: 0.5,
      intensityLightMaps: 0.1,
    },
  },
  modulrLobby: {
    fog: {
      density: 0.001,
      color: '#e6d2c7',
    },
    dirLightWithShadow: {
      color: '#DCDCDC',
      intensity: 1.5,
    },
    camera: {
      fov: 400,
    },
    sky: {
      enabled: true,
      azimuth: 1.41340734641021,
      inclination: 0.736,
      mixValue: -3,
    },
    water: {
      enabled: true,
      seaLevel: -5.32,
    },
    lightmap: {
      intensity: 1.1,
    },
    envMap: {
      intensity: 1,
      intensityLightMaps: 0.1,
    },
  },
  modulrLobbyDebug: {
    fog: {
      density: 0.001,
      color: '#e6d2c7',
    },
    dirLightWithShadow: {
      color: '#DCDCDC',
      intensity: 1.5,
    },
    camera: {
      fov: 400,
    },
    sky: {
      enabled: true,
      azimuth: 1.41340734641021,
      inclination: 0.736,
      mixValue: -3,
    },
    water: {
      enabled: true,
      seaLevel: -5.32,
    },
    lightmap: {
      intensity: 1.1,
    },
    envMap: {
      intensity: 1,
      intensityLightMaps: 0.1,
    },
  },
  modulrAuditorium: {
    fog: {
      density: 0,
      color: '#353B64',
    },
    dirLightWithShadow: {
      color: '#DCDCDC',
      intensity: 0.6,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: true,
      azimuth: -0.71,
      inclination: -0.765,
      mixValue: -3,
    },
    water: {
      enabled: true,
      seaLevel: -5.32,
    },
    lightmap: {
      intensity: 0.86,
    },
    envMap: {
      intensity: 1,
      intensityLightMaps: 0.136,
    },
  },
};

export const useEnvironmentStore = create(set => ({
  presets: presets,
  environmentConfiguration: JSON.parse(JSON.stringify(presets.lobby)),

  set: environmentConfiguration => {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(environmentConfiguration, null, 2));
    set({ environmentConfiguration: JSON.parse(JSON.stringify(environmentConfiguration)) });
  },

  setPresetByName: name => {
    set(state => {
      if (state.presets[name]) {
        return { environmentConfiguration: JSON.parse(JSON.stringify(state.presets[name])) };
      } else {
        return state;
      }
    });
  },
}));
